@use 'sass:map';
@import './mixins/tab.scss';
@import './mixins/search-form.scss';
@import './mixins/datatable.scss';
@import './mixins/grid.scss';
@import './mixins/form.scss';
@import "./functions";
@import "./variables";

@mixin shadow($color: rgba(0, 0, 0, 0.15)) {
  box-shadow: 0px 3px 5px 2px $color;
}

@mixin containerWithBoxShadow {
  box-shadow: rgba(0, 0, 0, 0.08) 1px 2px 15px 0px;
  background-color: $white;
}

@mixin card-banner-title {
  font-size: em(23.6px);
  line-height: em(29.23px);
  align-items: center;
  font-weight: 700;
}

@mixin font($size, $weight, $height) {
  font-size: $size;
  font-weight: map.get($weight, "weight");
  font-family: map.get($weight, "font-family");
  line-height: $height;
}
