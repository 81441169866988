.idp-bx-backdrop {
  &.modal-backdrop {
    background: #fff;
  }

  &.modal-backdrop.show {
    opacity: 1;
  }
}

.idp-bx-modal {
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    margin: auto;
    border: 0;
    width: 85%;
    overflow: unset;
  }

  .modal-header {
    border-bottom: 0;

    .close {
      padding: 15px 0;
      outline: none;
      color: #817d7c;
    }
  }
}
