$red500: #e31837;
$burgundyRed500: #A61242;
$green500: #51C21A;

$universalSetSnow: #f5f5f5;
$rgbaUniversalSetSnow: rgba(245, 245, 245, 0.5);

$white: #ffffff;
$black: #000000;
$transparent: transparent;

$orange50: #FFF3E5;
$orange500: #FF8300;
$orange600: #CF6A00;

$red40: #F9C4CC;
$red50: #FDE8EB;

$baseBlue50: #E5E5FF;
$baseBlue100: #BFBFFF;
$baseBlue200: #8C8CFF;
$baseBlue300: #4D4DFF;
$baseBlue400: #2626FF;
$baseBlue500: #0000FF;
$baseBlue600: #0000BF;
$baseBlue700: #000070;
$baseBlue800: #000040;
$baseBlue900: #000020;

$blue50: #E5F3FF;
$blue100: #bee1ff;
$blue200: #89c9ff;
$blue300: #55b1ff;
$blue400: #2099ff;
$blue500: #0086F8;
$blue600: #006dca;
$blue700: #00437c;
$blue800: #002a4e;
$blue900: #00111f;

$deep-blue: $baseBlue700;
$lavander: $baseBlue50;

$grey50: #F9F9F9;
$grey100: #E8E8E8;
$grey200: #D5D5D5;
$grey300: #C3C3C3;
$grey400: #ABABAB;
$grey500: #878787;
$grey600: #6c6c6c;
$grey600_Set2: #6B6B6B;
$grey700: #515151;
$grey800: #363636;
$grey900: #1B1B1B;
$rgbaGrey500: rgba(135, 135, 135, 0.25);
$Greyset1: rgba(135, 135, 135, 1);

$charcoalGrey50: #f4f4f6;
$charcoalGrey100: #d3d4dc;
$charcoalGrey200: #b2b4c2;
$charcoalGrey300: #7b7f97;
$charcoalGrey400: #4d5062;
$charcoalGrey500: #333541;
$charcoalGrey600: #292a34;
$charcoalGrey700: #1f2027;
$charcoalGrey800: #14151a;
$charcoalGrey900: #0a0b0d;
$stoneGrey50: #fbfbfc;
$stoneGrey100: #f5f6f7;
$stoneGrey200: #F0F2F4;
$stoneGrey300: #ECEFF1;
$stoneGrey400: #E8EBEE;
$stoneGrey500: #E6E9EC;
$stoneGrey600: #B3BDC6;
$stoneGrey700: #728394;
$stoneGrey800: #4B5763;
$stoneGrey900: #262c32;

$green50: #edfce5;
$green100: #d1f6bf;
$green200: #adf08c;
$green300: #88e959;
$green400: #63e226;
$green500: #51C21A;
$green600: #429e15;
$green700: #2e6d0f;
$green800: #193d08;
$green900: #0a1803;

$boxShadowBase: #101828;
$overlaycolor: rgba(51, 53, 65, .7);
$font-open: "Open Sans", sans-serif;

$fontthin: 100;
$fontextralight: 200;
$fontlight: 300;
$fontnormal: 400;
$fontmedium: 500;
$fontsemibold: 600;
$fontbold: 700;
$fontextrabold: 800;
$fontblack: 900;

$textxs: em(12, 22);
/* 12px */
$textsm: em(14, 22);
/* 14px */
$textbase: em(16, 22);
/* 16px */
$textlg: em(18, 22);
/* 18px */
$textxl: em(20, 22);
/* 20px */
$text1xl: em(24, 22);
/* 24px */
$text2xl: em(28, 22);
/* 28px */
$text3xl: em(32, 22);
/* 32px */

$textxspx: 12px;
/* 12px */
$textsmpx: 14px;
/* 14px */
$textbasepx: 16px;
/* 16px */
$textlgpx: 18px;
/* 18px */
$textxlpx: 20px;
/* 20px */
$text1xlpx: 24px;
/* 24px */
$text2xlpx: 28px;
/* 28px */
$text3xlpx: 32px;
/* 32px */

$margin-24: em(-24, 22);
/* -24px */
$margin-3: em(-3, 22);
/* -3px */
$margin-4: em(-4, 22);
/* -4px */
$margin-8: em(-8, 22);
/* -8px */
$margin-9: em(-9, 22);
/* -9px */
$margin0: 0;
/* 0 */
$margin3: em(3, 22);
/* 3px */
$margin4: em(4, 22);
/* 4px */
$margin5: em(5, 22);
/* 5px */
$margin6: em(6, 22);
/* 6px */
$margin8: em(8, 22);
/* 8px */
$margin9: em(9, 22);
/* 9px */
$margin10: em(10, 22);
/* 10px */
$margin12: em(12, 22);
/* 12px */
$margin16: em(16, 22);
/* 16px */
$margin18: em(18, 22);
/* 18px */
$margin20: em(20, 22);
/* 20px */
$margin24: em(24, 22);
/* 24px */
$margin32: em(32, 22);
/* 32px */
$margin40: em(40, 22);
/* 40px */
$margin48: em(48, 22);
/* 48px */

$margin0px: 0px;
$margin3px: 3px;
$margin5px: 5px;
$margin8px: 8px;
$margin16px: 16px;
$margin24px: 24px;

$margin-9px: -9px;
$margin-24px: -24px;
$margin4_5px: 4.5px;
$margin115_4px: 115.4px;

$padding-14: em(-14, 22);
/* 14px */
$padding0: 0;
$padding4: em(4, 22);
/* 4px */
$padding5: em(5, 22);
/* 5px */
$padding6: em(6, 22);
/* 6px */
$padding8: em(8, 22);
/* 8px */
$padding10: em(10, 22);
/* 10px */
$padding12: em(12, 22);
/* 12px */
$padding15: em(15, 22);
/* 12px */
$padding16: em(16, 22);
/* 16px */
$padding18: em(18, 22);
/* 18px */
$padding20: em(20, 22);
/* 20px */
$padding23: em(23, 22);
/* 23px */
$padding24: em(24, 22);
/* 24px */
$padding28: em(28, 22);
/* 28px */
$padding30: em(30, 22);
/* 30px */
$padding32: em(32, 22);
/* 32px */
$padding36: em(36, 22);
/* 36px */
$padding40: em(40, 22);
/* 40px */
$padding48: em(48, 22);
/* 48px */
$padding60: em(60, 22);
/* 60px */
$padding64: em(64, 22);
/* 64px */

$padding5px: 5px;
$padding6px: 6px;
$padding8px: 8px;
$padding10px: 10px;
$padding12px: 12px;
$padding16px: 16px;
$padding20px: 20px;
$padding24px: 24px;
$padding28px: 28px;
$padding30px: 30px;
$padding32px: 32px;
$padding48px: 48px;
$padding64px: 64px;
$padding100px: 100px;

$gap4: em(4, 22);
/* 4px */
$gap6: em(6, 22);
/* 6px */
$gap8: em(8, 22);
/* 8px */
$gap10: em(10, 22);
/* 10px */
$gap12: em(12, 22);
/* 12px */
$gap16: em(16, 22);
/* 16px */
$gap17: em(17, 22);
/* 17px */
$gap24: em(24, 22);
/* 24px */
$gap32: em(32, 22);
/* 32px */
$gap40: em(40, 22);
/* 40px */
$gap48: em(48, 22);
/* 48px */

$gap2px: 2px;
$gap4px: 4px;
$gap6px: 6px;
$gap8px: 8px;
$gap10px: 10px;
$gap12px: 12px;
$gap16px: 16px;
$gap17px: 17px;
$gap20px: 20px;
$gap24px: 24px;
$gap32px: 32px;
$gap40px: 40px;
$gap48px: 48px;

$lineheight12: em(12, 22);
/* 12px */
$lineheight16: em(16, 22);
/* 16px */
$lineheight20: em(20, 22);
/* 20px */
$lineheight24: em(24, 22);
/* 24px */
$lineheight28: em(28, 22);
/* 28px */
$lineheight30: em(30, 22);
/* 30px */
$lineheight32: em(32, 22);
/* 32px */
$lineheight44: em(44, 22);
/* 32px */

$lineheight12px: 12px;
/* 12px */
$lineheight16px: 16px;
/* 16px */
$lineheight20px: 20px;
/* 20px */
$lineheight24px: 24px;
/* 24px */
$lineheight28px: 28px;
/* 28px */
$lineheight30px: 30px;
/* 30px */
$lineheight32px: 32px;
/* 32px */

$roundednone: 0;
$roundedsm: 2px;
$rounded: 4px;
$roundedmd: 6px;
$roundedlg: 8px;
$roundedxl: 12px;
$rounded2xl: 16px;
$rounded3xl: 24px;
$roundedfull: 9999px;

$opacity0: 0;
$opacity5: 0.05;
$opacity25: 0.25;
$opacity50: 0.5;
$opacity100: 1;

$borderWidth: 1px;
$borderWidth1_5: 1.5px;
$borderWidth2: $borderWidth * 1.5;
$borderWidth3: $borderWidth * 2;
$borderWidth4: $borderWidth * 3;

$width2: em(2, 22);
/* 2px */
$width8: em(8, 22);
/* 8px */
$width12: em(12, 22);
/* 12px */
$width16: em(16, 22);
/* 16px */
$width18: em(18, 22);
/* 18px */
$width20: em(20, 22);
/* 20px */
$width24: em(24, 22);
/* 24px */
$width32: em(32, 22);
/* 32px */
$width36: em(36, 22);
/* 36px */
$width40: em(40, 22);
/* 40px */
$width48: em(48, 22);
/* 48px */
$width56: em(56, 22);
/* 56px */
$width64: em(64, 22);
/* 64px */
$width96: em(96, 22);
/* 96px */
$width135: em(135, 22);
/* 135px */
$width160: em(160, 22);
/* 160px */
$width200: em(200, 22);
/* 200px */
$width209: em(209.29, 22);
$width628: em(628.364, 22);
/* 628.364px */
$width768: em(768, 22);
/* 768px */

$width2px: 2px;
$width8px: 8px;
$width16px: 16px;
$width18px: 18px;
$width20px: 20px;
$width24px: 24px;
$width25px: 25px;
$width36px: 36px;
$width40px: 40px;
$width48px: 48px;
$width56px: 56px;
$width93_2px: 93.02px;
$width139px: 139.53px;
$width160px: 160px;
$width200px: 200px;
$width209px: 209.29px;

$height8: em(8, 22);
/* 8px */
$height16: em(16, 22);
/* 16px */
$height18: em(18, 22);
/* 18px */
$height20: em(20, 22);
/* 20px */
$height24: em(24, 22);
/* 24px */
$height32: em(32, 22);
/* 32px */
$height36: em(36, 22);
/* 36px */
$height40: em(40, 22);
/* 40px */
$height48: em(48, 22);
/* 48px */
$height56: em(56, 22);
/* 56px */
$height64: em(64, 22);
/* 64px */
$height96: em(96, 22);
/* 96px */

$height12px: 12px;
$height16px: 16px;
$height18px: 18px;
$height20px: 20px;
$height24px: 24px;
$height36px: 36px;
$height48px: 48px;
$height40px: 40px;
$height48px: 48px;
$height56px: 56px;
$height64px: 64px;

$heightdvhLess80: calc(100dvh - 80px);
$heightdvhLess129: calc(100dvh - 129px);
$widthdvhLess5rem: calc(100dvw - 5rem);
$widthdvhLess5rem: calc(100vw - 5rem);

$xs: 0;
$sm: 476px;
$md: 768px;
$lg: 1100px;
$xl: 1200px;
$xxl: 1366px;
$xxxl: 1600px;
$xxxxl: 1920px;

$top16: em(16, 22);

$greywhite: #B8B9BA;
$greywhite2: #FAFAFA;
$greyblack: #1C1C1C;
$blackshadow: rgba(16, 24, 40, 0.1);
$blackshadowfade: rgba(16, 24, 40, 0.05);


//modal popup styles as per figma
$bordeRadius0_89rem: 0.8888888888888889rem;
$gap0_83rem: 0.8333333333333333rem;
$padding1_3rem: 1.333333333333333rem;
$width2_6rem: 2.666666666666667rem;
$lineheight1_6rem: 1.666666666666667rem;
$fontSize0_7rem: 0.7777777777777778rem;
$borderRadius0_22rem: 0.2222222222222222rem;
$padding0_66rem: 0.6666666666666667rem;
$bottom0_56rem: -0.0555555555555556rem;
$bottom300px: -300px;
$secondary-modal-gray: #525252;
// ------------------------------------------ttvariables--------------------------------------
$paymentcard-shadow: rgba(16, 24, 40, 0.1);
// ------------------------------------------- tt new variable-------------------------------------
$bookingboxshadow: #1018280D;
$bookinggray: #6B6B6B;
$BookingListShadow: #00000033;
$promo-shadow: #1018280D;
$red10: #FCE2EA66;
$Aubergine: #8A0F4A;
$gray50: #FAFAFA;
$btn-gray: #343642;
$overlayshadow: rgba(51, 53, 65, .7);
$red700: #8E0F22;
$tertiary-light: #FDE0D4;
$tertiary-dark: #AA461D;