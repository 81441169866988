@import "./24_variables.scss";

/**
* use this to customize accordion design.
*/

.accordion.theme-1 {
  display: flex;
  flex-direction: column;
  gap: $gap24px;
  .accordion-item {
    overflow: visible;
    border-radius: $roundedlg;
    border: 1px solid rgba($charcoalGrey500, 0.25);

    .accordion-header {
      border-bottom: 0;
      background-color: transparent;
      padding: $padding16px;
      overflow: hidden;
    }

    .accordion-body {
      padding: $padding24;
      padding-top: 0;
    }

    .material-icons {
      color: $charcoalGrey500;
    }

    &:has(.collapse.show) {
      border: 1.5px solid $charcoalGrey500;
      border-radius: $roundedlg;
    }
  }
}