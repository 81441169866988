@import "./variables";
@import "./functions";
@import "./24_variables.scss";

.flexbox {
  display: flex;
}
.paystack-paragraph{
p {
  font-size: 0.7777777777777778rem;
  line-height: 1.333333333333333rem;
  font-weight: $fontsemibold;
  color: $charcoalGrey500;
  margin: 0;
  text-align: center;
  font-family: $font-open;
}
}

.calculatedPadding {
  padding-left: clamp(610px, 40vw, 40vw);
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.w-fit {
  width: fit-content;
}

.custom-validation-error {
  color: $dark-red;
}

.custom-toast-error {
  width: calc(100% - 120px);
  top: 60px;
  left: 60px;
  right: 60px;

  .ngx-toastr {
    width: 100%;
  }
}

.cursor-pointer {
  cursor: pointer;
}

@include media-breakpoint-up(xl) {
  .content-wrapper {
    position: relative !important;

    .main-content {
      padding-top: 30px;
      padding-left: clamp(610px, 40vw, 40vw);
      height: 100%;
      position: inherit;
    }

    .responsiveContainer {
      padding-left: clamp(610px, 40vw, 40vw);
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .content-wrapper {
    position: relative !important;

     .main-content {
       padding-top: 30px;
       padding-left: clamp(610px, 30vw, 40vw);
       padding-right: 25px;
       position: inherit;
       height: 100%;
       overflow-y: hidden;
     }

    .responsiveContainer {
      padding-left: clamp(610px, 40vw, 40vw);
    }
  }
}

@include media-breakpoint-down(xl) {
  .content-wrapper {
    width: 100%;
    align-self: center;

    .main-content {
      width: 100%;
    }
  }
}
 a {
  text-decoration: none;
 }
 a:hover {
  text-decoration: underline;
 }
 a.disabled {
   pointer-events: none;
   cursor: default;
   text-decoration: none;
   color: black;
 }


.ssr-location-popover {
  border: 0;
  padding: 0 0 55px;
  border-radius: em(16px);
  max-width: 100%;
  width: 100%;

  .arrow {
    display: none;
  }

  .popover-body {
    background-color: $white;
    box-shadow: em(6px) em(6px) em(5px) rgba($black, 0.1);
    border: em(1px) solid $light-grey;
    padding: em(25px);
    min-width: em(518px);
    border-radius: em(16px);
    position: relative;
  }
}
@include media-breakpoint-up(lg){
.paystack-paragraph{
  p{
    font-size: em(18px);
    line-height: em(32px);
    text-align: center;
  }
}
}
