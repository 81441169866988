@import "./variables";
.offcanvas.theme-1 {
  $border-config: 1px solid rgba(gray, 0.25);

  &.offcanvas-end {
    width: 50%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .offcanvas-header {
    padding: 1.3334rem 1.78rem;
    border-bottom: $border-config;

    .offcanvas-title {
      font-size: 1.3334rem;
      line-height: 1.5556rem;
      font-weight: 600;
      text-wrap: balance;
    }
  }

  .offcanvas-body {
    font {
      line-height: normal;
    }

    h2 {
      font-size: $fontSize28;
      line-height: $lineHeight32;
    }

    h4 {
      font-size: $fontSize20;
      line-height: $lineHeight24;
      color: $dark-red;
    }

    p {
      font-size: $fontSize16;
      line-height: $lineHeight28;
    }
  }

  .offcanvas-footer {
    padding: 1.1112rem 1.78rem;
    border-top: $border-config;

    button {
      font-size: 0.8989rem;
      width: 100%;
      border-radius: 4px;
    }
  }
}