/* You can add global styles to this file, and also import other style files */

@import "node_modules/bootstrap/dist/css/bootstrap.min.css";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/utilities/api";

@import "sass/app-theme";
@import "node_modules/ngx-toastr/toastr.css";
.main{
  	min-height:666px;
}
::-ms-clear { display: none; }
@include media-breakpoint-down(md) {
  bx-search-container{
    min-width:360px;
  }
}
@include media-breakpoint-up(sm) {
  bx-search-container{
    background:$white;
    display: block;
    margin: 0 auto;
  }
}
.idp-bx-swipe-left{
	animation-name: ltr_page;
    animation-duration: 0.5s;
}
.idp-bx-swipe-right{
	animation-name: rtl_page;
    animation-duration: 0.5s;
}
.ng-trigger-routeAnimations{
	overflow: hidden;
}
@keyframes ltr_page {
  from {right:-100vw;}
  to {right:0vw;}
}
@keyframes rtl_page {
  from {right:100vw;}
  to {right:0vw;}
}
.btn-highlight {
  &:focus {
    outline: 1px dotted $blue;
    box-shadow: 0 0 2em rgba(255, 255, 255, 0.75);
  }
}
.bck-highlight{
  &:focus {
    outline: 1px dotted $blue;
  }
}
.back-btn-highlight {
  &:focus {
    outline: 1px solid $black;
  }
}
/* fix double scroll issue caused by gtm injected invisible img tag */
body > img {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

ol > li::marker { 
  content: counters(list-item, '.') '. ';
}

#inicisModalDiv.inipaypro_modal.fade:not(.show) {
  opacity: 1;
}

/*Custom scroll*/
.custom-scroll::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: $grey500;
  border-radius: 4px;
}

#onetrust-banner-sdk #onetrust-button-group #onetrust-accept-btn-handler {
  border-radius: mem(100px);
}

@include media-breakpoint-up(md) {
  #onetrust-banner-sdk #onetrust-button-group #onetrust-accept-btn-handler {
    border-radius: em(100px);
  }
}