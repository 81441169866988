@use 'sass:map';
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/_variables";
@import "./functions";
@import "./variables";
@import "./mixins";
@import url('https://use.typekit.net/eol6bti.css');


%btn {
  font-size: em(20px, $regularBaseSize);
  font-weight: bold;
}

%link {
  text-decoration: none;
  font-weight: map.get($weightMedium, "weight") !important;
}

html {
  font-size: 137.5%;
}

/*22px*/

body {
  background: white;
  font-family: 'proxima-nova', Arial, sans-serif;
  font-weight: 400;
  line-height: em(26px, $regularBaseSize);
  color: $black;
}

p {
  margin-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 3rem 0 1.38rem;
  font-weight: 400;
  line-height: 1.3;
  color: $dark-grey;
}

b {
  font-weight: map.get($weightBold, "weight");
}

h1 {
  margin-top: 0;
  @include font(em(51px, $regularBaseSize), $weightSemi, em(45px, $regularBaseSize));
}

h2, .h2 {
  @include font(em(27px, $regularBaseSize), $weightSemi, em(30px, $regularBaseSize));

}

h3 {
  @include font(em(20px, $regularBaseSize), $weightSemi, em(24px, $regularBaseSize));
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 1rem;
  font-weight: map.get($weightSemi, "weight");
}

.a-link {
  font-weight: map.get($weightSemi, "weight");
  font-size: 18px;
}

.text-bold {
  font-weight: map.get($weightSemi, "weight");
}

.weight-semi {
  font-weight: map.get($weightSemi, "weight");
}

.weight-bold {
  font-weight: map.get($weightBold, "weight");
}

.sub-text {
  font-size: 14px;
}

small,
.text_small,
.text-small {
  font-size: 0.8rem !important;
}

.text-medium {
  font-weight: map.get($weightMedium, "weight") !important;
}

.leadText {
  font-size: em($regularBaseSize, $regularBaseSize);
  line-height: em(26px, $regularBaseSize);
}


input {
  @include font(em(16px, $regularBaseSize), $weightSemi, em(17px, $regularBaseSize));

  &::placeholder {
    font-size: em(16px, $regularBaseSize);
    line-height: em(17px, $regularBaseSize);
    color: #B2B2B2 !important;
  }
}

.invalid-feedback {
  font-size: em(12px, $regularBaseSize);
  line-height: em(14px, $regularBaseSize);
}

.text-darker {
  color: $gray-900;
}

.text-dark {
  color: $gray-700;
}

.cursor-pointer {
  cursor: pointer;
}


.text-blue {
  color: $blue
}

.text-grey {
  color: $grey;
}

.text-black {
  color: $black;
}

.text-gray {
  color: $textGray;
}

.text-purple {
  color: $purple
}

.text-dark-red {
  color: $dark-red-info-box;
}

.text-dark-grey {
  color: $dark-grey;
}

.text-orange {
  color: $orange;
}

.text-pink {
  color: $text-pink
}

.title-text {
  font-size: 18px;
}

a {
  &.btn {
    @extend %btn;
  }

  &.btn-link {
    font-size: em(14px, $regularBaseSize);
  }

  &.link-dark {
    @extend .text-dark;
    @extend %link;
  }

  &.link-grey {
    @extend .text-grey;
    @extend %link;
  }

  &.link-darker {
    @extend .text-darker;
    @extend %link
  }
}

button.btn {
  @extend %btn;

  &.btn-link {
    font-size: em(14px, $regularBaseSize);
  }
}

legend {
  font-size: $regularBaseSize !important;
  font-weight: map.get($weightRegular, "weight") !important;
}

.sub-copy {
  @include font(em(14px, $regularBaseSize), $weightRegular, em(17px, $regularBaseSize));
}

.content-box-description {
  @include font(em(18px, $regularBaseSize), $weightRegular, em(21px, $regularBaseSize));
}

.Instructional-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

@include media-breakpoint-down(lg) {
  html {
    font-size: 112.5%;
    /*18px*/
    line-height: em(20px, $mobileBaseSize);
  }

  h1 {
    @include font(em(35px, $mobileBaseSize), $weightSemi, em(30px, $mobileBaseSize));
  }

  h2 {
    @include font(em(18px, $mobileBaseSize), $weightSemi, em(24px, $mobileBaseSize));
  }

  .leadText {
    font-size: em(16px, $mobileBaseSize);
    line-height: em(17px, $mobileBaseSize);
  }

  .input {
    font-size: em(16px, $mobileBaseSize);
    line-height: em(17px, $mobileBaseSize);

    ::placeholder {
      font-size: em(16px, $mobileBaseSize);
      line-height: em(17px, $mobileBaseSize);
    }
  }

  .invalid-feedback {
    font-size: em(10px, $mobileBaseSize);
    line-height: em(12px, $mobileBaseSize);
  }

  %btnMobile {
    font-size: em($mobileBaseSize, $mobileBaseSize);
    font-weight: bold;
  }

  a {
    &.btn {
      @extend %btnMobile;
    }

    &.btn-link {
      font-size: em(14px, $regularBaseSize);
    }
  }

  button.btn {
    @extend %btnMobile;
  }

  legend {
    font-size: $mobileBaseSize !important;
    font-weight: map.get($weightRegular, "weight") !important;
  }

  .Instructional-text {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
  }
}

.underlined-title {
  &::after {
    width: 70px;
    height: 4px;
    margin-top: 16px;
    background: transparent linear-gradient(90deg, #E01E3C 0%, #FF8300 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    border-radius: 2px;
    display: block;
    content: "";
  }
}

.card-description {
  font-size: 12px;
  line-height: 15px;
  @include media-breakpoint-up(lg) {
    font-size: em(14px);
    line-height: em(17.05px);
  }
}

.card-item-header {
  font-size: 14px;
  line-height: 14px;
  @include media-breakpoint-up(lg) {
    font-size: em(20px);
    line-height: em(24px);
  }
}

.card-mobile-month-banner {
  font-size: 19px;
  line-height: 16px;
  font-weight: 500;
}

i.fa.popover-icon-size {
  font-size: 20px;
}

i.fa.inline-icon-size {
  font-size: 16px;
}

.card-mobile-day-banner {
  font-size: 16px;
  line-height: 16px;
}

.card-mobile-time-banner {
  font-size: 32px;
  line-height: 24px;
  font-weight: 600;

  & > span {
    font-size: 16px;
  }
}

.label-font {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.416px;
}

.product-font {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.32px;
}

.card-mobile-date-banner {
  font-size: 40px;
  line-height: 46px;
  font-weight: 600;
}

.popover-description {
  font-size: em(15px);
  line-height: em(18px);
}

.font-initial {
  font-size: initial;
}

.font-16 {
  font-size: 16px;
}

.font-27 {
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.54px;
}

.font-40 {
  font-size: 40px;
}

@mixin timerAlignChildComponentStyles {
  /* Override child component styles here */
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: -0.572px;
}

@mixin font-18 {
  /* Override child component styles here */
  font-size: 18px;
}

@mixin font-22 {
  font-size: 22px;
}

@mixin font-30 {
  font-size: 30px;
}

@mixin font-35 {
  font-size: 35px;
  line-height: 39px;
}

@mixin font-14 {
  font-size: 14px;
}

@mixin font-16 {
  font-size: 16px;
}

@mixin font-40 {
  font-size: 40px;
}

@mixin font-0-7rem {
  font-size: 0.7rem;
}

@mixin font-0-8rem {
  font-size: 0.8rem;
}

@mixin font-1rem {
  font-size: 1rem;
}

.field-height{
  max-height: 2.29rem;
}

