
@use "sass:color";
@import "./variables";

.btn-singleAction {
  @include button-variant($white,
    $borderGrey,
    $darkRed,
    $hover-border: null,
    $active-background: darken($darkRed, 5%));
  border-radius: 3px;
  width: 275px;
  height: 60px;
  color: $darkRed;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $white;
    background-color: $darkRed;
    text-decoration: none;
  }

  &:disabled {
    background-color: opacify($color: $gray-400, $amount: 0.35);
    color: #777;

    &:hover {
      color: #777;
    }
  }
}

.btn-multiAction {
  @include button-variant($blue,
    $white,
    $neutral-grey,
    $hover-border: null,
    $active-background: darken($neutral-grey, 5%));
  border-radius: 3px;
  width: 275px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  transition: background-color 0.3s ease;

  &:hover {
    color: $white;
    background-color: $neutral-grey;
    border-color: $neutral-grey;
  }

  &:disabled {
    background-color: opacify($color: $neutral-grey, $amount: 0.35);
    color: $disable-gray;

    &:hover {
      color: $disable-gray;
    }
  }

  &:focus {
    box-shadow: 0px 0px 9px color.adjust($blue, $alpha: .8) !important;
  }
}

.btn-payAction {
  @include button-variant($pay-green,
    $white,
    $neutral-grey,
    $hover-border: null,
    $active-background: darken($neutral-grey, 5%));
  border-radius: 3px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;

  &:disabled {
    background-color: opacify($color: $neutral-grey, $amount: 0.35);
    color: $disable-gray;

    &:hover {
      color: $disable-gray;
    }
  }
}

.btn-errorAction {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: opacify($color: $primary-red, $amount: 1) !important;
    color: $white;

    &:hover {
      color: $disable-gray;
    }
  }
}

%btn-irrelevantAction {
  @include button-outline-variant($blue, darken($blue, 10), $white, $blue);
  border-radius: 3px;
  min-width: 200px;
  height: 60px;
  color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: opacify($color: $neutral-grey, $amount: 0.35);
    color: $disable-gray;
    border: none;

    &:hover {
      color: $disable-gray;
    }
  }
}

.btn-irrelevantAction {
  @extend %btn-irrelevantAction;

  &:hover {
    color: $white;
    background-color: $blue;
  }
}

.btn-irrelevantAction-focus {
  @extend %btn-irrelevantAction;

  &.isActive:not(:disabled) {
    color: $white;
    background-color: $blue;
  }
}

.actionbtn {
  min-width: 180px;
  height: 48px;
  order: 2;
  margin-left: auto;
  background-color: $primary-red;
  color: $white;
  border: 0;
  border-radius: 3px;
  box-shadow: 0px 3px 6px $light-black;

  &:disabled {
    background-color: $neutral-grey;
    color: $white;
    border: none;
    text-decoration: none;
  }
}

.btn-white {
  border: 1px solid $blue;
  background-color: $white;
  border-radius: 3px;
  min-width: 177px;
  height: 64px;
  color: $blue;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  font-size: 20px;
  padding: 20px;

  &:hover {
    text-decoration: underline;
    border: 4px solid $blue;
  }

  &:disabled {
    color: $neutral-grey;
    border: none;
    text-decoration: none;
  }
}

.btn-blue {
  padding: 20px;
  background-color: $blue;
  border-radius: 3px;
  min-width: 177px;
  height: 64px;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $font-weight-bold;
  font-size: 20px;
  border: 0;
  outline: none !important;

  &:hover {
    background-color: $neutral-grey;
  }

  &:disabled {
    background-color: $neutral-grey;
  }

  &:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25) !important;
  }

}

.btn-link {
  font-weight: $font-weight-normal;
  color: #000000;
  text-decoration: none;

  svg {
    fill: $blue;
  }

  &:hover {
    color: #000000;
    text-decoration: underline;

    .material-icons {
      text-decoration: none;
    }

    .fill {
      color: darken($blue, 5);
    }
  }

  &:focus,
  &.focus {
    text-decoration: none;
  }

  &:disabled,
  &.disabled {
    color: $neutral-grey;
    pointer-events: none;
  }

  .material-icons {
    text-decoration: none;
  }

  // No need for an active state here
}

.btn-focus {
    &:focus {
        border: 1px solid $black;
    }
}
