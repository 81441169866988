@import "./variables";

/**
* use this to customize alert design.
*/

.alert-warning {
    color: $alert-warning-color;
    background-color: $alert-warning-bg;
    border-color: $alert-warning-border;
 }

.alert-custom-lavander {
    color: $alert-deep-blue-color;
    background-color: $alert-lavander-bg;
    border-color: $alert-lavander-border;
}
