/* Build a customized bootstrap 4 theme, to better suit the application's branding.

RECOMMENDED APPROACH:

    Try using one of these Bootstrap theme builder's, to modify bootstrap's built in variables
        https://bootstrap.build/app (Comes with a LIVE preview of changes)
    or 
        https://themestr.app/customize (more of a code/variable based view)

    * Typically, you should just need to apply changes to the

        1. Colours, Typography
        2. Tables, Forms, Buttons
        3. Navbar
        4. Modals, Alerts

NOTE: 
    Creating a properyly themed set of bootstrap variables, is highly recommended, 
    so the actual bootstrap framework does get totally trashed, with css customizations everywhere.

*/

//Include all Bootstrap 4 vars, mixins and functions
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/_variables";

@import "./_mixins";
@import "./_variables";

@import "./custom-modal";
@import "./functions";
@import "./font.scss";
@import "./button.scss";
@import "./custom.scss";
@import "./alert.scss";
@import "./badge.scss";
@import "./accordion.scss";
@import "./page/update-details.scss";
@import "./override.scss";
@import "./offcanvas.scss";