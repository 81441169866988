$payment-button: #4DB24A;
$dark-green: #008002;
$dark-orange: #FF8300;
$orange: #F5941D;
$purple: #BD60A5;
$green: #9ACA3C;
$pay-green: #53A700;
$blue: #00AAAD;
$link-blue: rgba(0, 123, 255);
$turquoise: #23B5B8;
$primary-red: #E31837;
$dark-red: #A2071F;
$dark-red-info-box: #B5142C;
$darkRed: #A2071F;
$badge-bg-red: rgba(220, 53, 69, 0.1);
$badge-bg-blue: rgba(53, 220, 210, 0.1);
$white: #FFFFFF;
$light-grey: #EEEEEE;
$mid-grey: #5A5A5A;
$primary-grey: #A59D95;
$grey: #828282;
$neutral-grey: #565656;
$dark-grey: #2C2C33;
$payment-icon-grey: #676563;
$grey-60: #606060;
$grey-70: #707070;
$black: #000000;
$light-black: #00000029;
$black-shadow: #0000001A;
$gray: #828282;
$text-gray: $dark-grey;
$border-grey: #DBD6D2;
$border-color: #bfbdbc;
$white: #fff;
$textgrey: #495057;
$card-dark: #323541;
$text-pink: #C15B7A;
$lavander: #E5E5FF;
$deep-blue: #080875;
$errorRed: rgba(#dc3545, 100);
$borderGrey: #ededed;
$inputBorderGrey: rgb(200, 200, 200);
$placeholderGrey: rgb(211, 205, 205);
$disabledBtnGrey: rgb(242, 242, 242);
$formLabelBlack: rgba($text-gray, 100);
$modalHeaderColor: rgba(#4c5455, 100);
$backgroundGrey: rgb(248, 249, 253);
$backgroundColor: #F3F3F3;
$disabledLabelColor: #e9ecef;
$statusRed: rgb(240, 8, 7);
$statusGreen: rgb(59, 179, 79);
$inputStatusGreen: #00AAAD;
$statusOrange: rgb(254, 172, 42);
$mdBreakpoint: 768px;
$primary: rgb(216, 3, 3);
$primary-light: lighten($primary, 20%);
$navBarBackground: $white;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1100px,
  xl: 1200px,
  xxl: 1366px
);
$container-max-widths: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1100px,
  xl: 1200px,
  xxl: 1366px
);
$neutralGrey: #565656;
$textGray: #656565;
$cardGray: #efefef;
$btn-social-login: #E32836;
$actionBtnHover: #212529;
$placeholder-gray: #6d6d6d;
$border-gray: #7d7d7d;
$disable-gray: #9e9e9e;
$focus-grey: #e6e6e6;
$mobile-blue-faded: #E5F6F7;
$desktop-blue-faded: #DDEEEE;
$gradient1: linear-gradient(312deg, rgba(227, 24, 55, 1) 0%, rgba(114, 12, 28, 1) 100%);
$gradient2: linear-gradient(151deg, rgba(224, 30, 60, 1) 0%, rgba(252, 122, 5, 1) 89%, rgba(255, 131, 0, 1) 100%);
$gradient3: linear-gradient(151deg, rgba(1, 165, 167, 1) 0%, rgba(154, 202, 60, 1) 100%);
$gradient4: linear-gradient(136deg, rgba(0, 53, 117, 1) 0%, rgba(189, 96, 165, 1) 100%);
$gradient5: linear-gradient(121deg, #EEEEEE, #cbd2eb);
$paging-teal: #56999C;
$alert-warning-bg: #FFF3E5;

$alert-warning-color: $dark-orange;
$alert-warning-border: $dark-orange;
$alert-lavander-bg: $lavander;
$alert-deep-blue-color: $deep-blue;
$alert-lavander-border: $lavander;
$badge-success-bg: #e3fad7;
$badge-success-color: $statusGreen;
$background_grey_light: #f5f5f5;
$background_grey_lighter: #f8f9fa;
$light-green: #009C9D;
$input-border-radius: 8px;

$weightRegular: (
  "weight": 400
);
$weightMedium: (
  "weight": 500
);
$weightSemi : ("weight": 600);
$weightBold : ("weight": 700);
$regularBaseSize: 22px;
$mobileBaseSize: 18px;
$bookingFontSize: 40px;

$lineHeight24: 24px;
$lineHeight28: 28px;
$lineHeight32: 32px;

$fontSize16: 16px;
$fontSize18: 18px;
$fontSize20: 20px;
$fontSize22: 22px;
$fontSize28: 28px;
$fontSize40: 40px;


$padding22:22px;
$gray-medium: #6B6B6B;
$fontSize28: 28px;
