@import "../variables";
@import "sass/font.scss";

#updateDetails {
    font-size: 0.8rem;

    .card,
    .card:not(:first-of-type):not(:last-of-type) {
        background-color: transparent;
        border: 2px solid $light-black;
        margin-bottom: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 8px;
    }

    .card:not(:first-of-type):not(:last-of-type) {
        border-bottom: 2px solid $light-black;
        border-radius: 8px;
    }

    .accordion-body {
        border-left: 0;

        input#mobileNumber,
        input#birthDate {
            font-size: 0.8rem;
        }

        .form-label {
            font-weight: bold;
        }
    }

    .card-header {
        background-color: transparent;
        border: 0;

        button {
            padding: 0;
            line-height: 0;
        }

        h2 {
            margin: 0;
            color: $dark-grey;
        }

    }

    .form-control {
        font-size: 0.8rem;
    }

    .uploadBoxWrapper .uploadBox {
        @include media-breakpoint-up(lg) {
            height: 50vh;
            max-width: 50vw;
        }
    }

    .select-trigger .selectedItem {
        font-size: 0.8rem;
    }

    .badge-danger {
        color: $errorRed;
        background-color: $badge-bg-red;
        padding: 10px;
        font-size: 14px;
    }

    .badge-primary {
        color: $blue;
        background-color: $badge-bg-blue;
        padding: 10px;
        font-size: 14px;
    }

    .custom-btn {
        border-radius: 4px;
        width: 200px;
        height: 45px;
        font-size: 0.8rem;
    }

    .custom-outline-btn {
        border-radius: 4px;
        width: 200px;
        height: 45px;
        font-size: 0.8rem;
        color: $dark-grey;
        border: 2px solid $dark-grey;
        background-color: white;
    }

    .btn-danger {
        color: $white;
        background-color: $errorRed;
    }

    .heading {
        font-size: 0.8rem;
        font-weight: bold;
    }

    .card-action {
        display: flex;
        gap: 30px;
    }

    .text-sm {
        @include font-16;
    }

    .title-padding {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .me-20 {
        margin-right: 20px;
    }

    @media only screen and (max-width: 370px) {
        input#mobileNumber {
            padding-left: 72px;
        }
    }

}
